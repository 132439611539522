import * as React from "react"
import * as styles from './AboutSection.module.css';
import { StaticImage } from "gatsby-plugin-image"
import { useRef } from 'react';
import useOnScreen from '../../hooks/use-on-screen';

const AboutSection = () => {
    const ref = useRef();
    const onScreen = useOnScreen(ref, '-200px');

    return (
        <div className={styles.aboutSection} id="about" ref={ref}>
            <div className={styles.skew1} />
            <div className={styles.skew2} />

            <div className={styles.title}>
                <h2>Über mich</h2>
                <h3>Nina Jansen</h3>
            </div>

            <StaticImage
                src="../../assets/person.jpg"
                alt="Portäit Nina Jansen | Kosmetik & Fußpflege | Grevenbroich-Wevelinghoven"
                title="Portäit Nina Jansen | Kosmetik & Fußpflege | Grevenbroich-Wevelinghoven"
                width={769}
                height={1068}
                className={`${styles.image} ${onScreen ? styles.imageInView : ''}`}
            />

            <p className={styles.description}>
                Mein Name ist Nina Jansen. Seit über {(new Date().getFullYear()) - 2005} Jahren arbeite ich in Grevenbroich-Wevelinghoven als Ganzheitskosmetikerin.
                Gesichtspflege, med. Fußpflege, Maniküre, Massagen - dies und vieles mehr erwartet Sie in angenehmer Wohlfühlatmosphäre bei Ihrem Kosmetikstudio Ninas Beauty Oase.
                <br />
                <br />
                Schauen Sie Sich gerne auf meiner Internetseite um, und zögern Sie nicht, mich anzurufen.
                Gerne beantworte ich alle Ihre Fragen im vertrauensvollen Gespräch!
                <br />
                Sie erreichen mich telefonisch unter: <a href="tel:+4921817059090" title="Telefon Ninas Beauty Oase">02181 7059090</a>
                <br />
                <br />
                Ich freue mich auf Ihren Anruf und Besuch!
                <br />
                <br />
                Ihre Nina
            </p>

        </div>
    )
}

export default AboutSection