import * as React from "react"
import * as styles from './Footer.module.css'
import { HiOutlineExternalLink } from 'react-icons/hi'
import { BiCopyright } from 'react-icons/bi'

const imageCopyrightLink = 'https://www.freepik.com/free-vector/hand-painted-watercolor-abstract-watercolor-background_15273930.htm#page=2&query=beauty&position=21&from_view=search';

const Footer = () => {
    return (
        <footer className={styles.footer} id="footer">

            <section className={styles.openingTimesContactSection}>
                <section>
                    <h3>Öffnungszeiten</h3>
                    <p>Geöffnet nach Termin.</p>
                </section>

                <section>
                    <h3>Impressum</h3>
                    <p>Nina Jansen</p>
                    <p>Lerchenweg 39, 41516 Grevenbroich</p>
                    <p><a href='tel:+4921817059090' title="Telefon Ninas Beauty Oase">Telefon: 02181 7059090</a></p>
                </section>
            </section>

            <section className={styles.copyrightSection}>
                <p><BiCopyright className={styles.icon}/> {(new Date().getFullYear())} Nina Jansen</p>
                <p>
                    <a href={imageCopyrightLink} target='_blank' rel="noreferrer" title="Copyrigth Header Image">
                        Image by Freepik <HiOutlineExternalLink className={styles.icon} />
                    </a>
                </p>
            </section>
        </footer>
    )
}

export default Footer