// extracted by mini-css-extract-plugin
export var arrow = "Gallery-module--arrow--bXU9h";
export var chevron = "Gallery-module--chevron--tOb1c";
export var gallery = "Gallery-module--gallery--F-jDH";
export var image = "Gallery-module--image--CCIop";
export var imageActive = "Gallery-module--image-active--VX4Lo";
export var indicator = "Gallery-module--indicator--axGbV";
export var indicatorActive = "Gallery-module--indicator-active--aTkD+";
export var indicatorSection = "Gallery-module--indicator-section--3Bjk2";
export var left = "Gallery-module--left--tG9L7";
export var right = "Gallery-module--right--Jd0pb";
export var skew1 = "Gallery-module--skew1--j-mkY";
export var skew2 = "Gallery-module--skew2--4AEYX";