import * as React from "react"
import * as styles from './Contact.module.css'
import { FiArrowRight } from 'react-icons/fi'
import { ImPhone } from 'react-icons/im'
import { StaticImage } from "gatsby-plugin-image"
import { useRef } from 'react';
import useOnScreen from '../../hooks/use-on-screen';

const Contact = () => {
    const ref = useRef();
    const onScreen = useOnScreen(ref, '-100px');

    return (
        <div id="contact" ref={ref} className={`${styles.contact} ${onScreen ? styles.contactInView : ''}`}>
            <section className={styles.welcomeSection}>
                <section className={styles.imageSection}>
                    <StaticImage
                        src="../../assets/person.jpg"
                        alt="Portäit Nina Jansen | Kosmetik & Fußpflege | Grevenbroich-Wevelinghoven"
                        title="Portäit Nina Jansen | Kosmetik & Fußpflege | Grevenbroich-Wevelinghoven"
                        width={769}
                        height={1068}
                    />
                </section>
                <section>
                    <h3 >Ninas Beauty Oase</h3>
                    <p>
                        Herzlich willkommen bei Ihrem Kosmetikstudio in Grevenbroich-Wevelinghoven.
                        <br />
                        Rufen Sie mich gerne an, ich freue mich auf Sie!
                    </p>
                </section>
            </section>

            <section className={styles.informationSection}>
                <section>
                    <h4>Geöffnet nach Termin.</h4>
                </section>
                <a href='tel:+4921817059090' title="Telefon Ninas Beauty Oase">
                    <section className={styles.makeAppointment}>
                        <FiArrowRight />
                        <p>
                            Jetzt Termin vereinbaren
                        </p>
                    </section>
                </a>
                <p>
                    <ImPhone className={styles.icon} />
                    <a href='tel:+4921817059090' title="Telefon Ninas Beauty Oase">02181 7059090</a>
                </p>

                <p>
                    Lerchenweg 39, 41516 Grevenbroich
                </p>


            </section>

            <section className={styles.mapSection}>
                <iframe
                    title="Google Maps | Ninas Beauty Oase | Lerchenweg Lerchenweg 39, 41516 Grevenbroich-Wevelinghofen"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d20038.404389703956!2d6.630400000000001!3d51.11214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf4b74f331ccdd%3A0xa7c935270bc76875!2sNina%C2%B4s%20Beauty%20Oase%20Kosmetik%20%26%20Fu%C3%9Fpflege!5e0!3m2!1sde!2sde!4v1661167970645!5m2!1sde!2sde"
                    className={styles.map}
                    height="450"
                    allowFullScreen="allowfullscreen"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                />
            </section>
        </div>

    )
}

export default Contact
