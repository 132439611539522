import * as React from "react"
import * as styles from './ServicesContainer.module.css'
import ServicesRow from './ServicesRow'
import { useState, useEffect } from "react"

const ServicesContainer = () => {
  const [apiResponse, setApiResponse] = useState([]);

  useEffect(() => {
    fetch(`https://ninasbeautyoase.com/api/getData.php`, {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => response.json())
      .then(resultData => {
        setApiResponse(resultData);
      });
  }, []);

  return (
    <div className={styles.servicesContainer} id="prices">
      <div className={styles.title}><h2>Unsere Leistungen</h2></div>

      {
        apiResponse.map((element, index) => {
          return (
           <ServicesRow title={element.name} services={element.services} key={element.id} />
          );
        })
      }
      <section className={styles.information}>
        <p>Gerne besprechen wir Ihre individuellen Wünsche per Telefon!</p>
      </section>
    </div>
  )
}

export default ServicesContainer

class ServicesRowContent {
  title;
  services = [];
  constructor(title) {
    this.title = title;
  }
}