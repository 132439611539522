import * as React from "react"
import * as styles from './Header.module.css';
import "@fontsource/dancing-script"
import scrollDownGif from '../../assets/scroll-down.gif'
import { StaticImage } from "gatsby-plugin-image"

const Header = () => {
  return (
    <div className={styles.header}>
      <StaticImage src="../../assets/header.jpg" alt="" title=""/>
      <img src={scrollDownGif} alt="Please scroll down GIF" className={styles.headerScrollDown} />
      <h1 className={styles.headerTitle}>Ninas Beauty Oase</h1>
      <h2 className={styles.headerSubtitle}>Herzlich willkommen!</h2>
    </div>
  )
}

export default Header