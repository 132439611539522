import * as React from "react"
import * as styles from './Gallery.module.css'
import { useState, useEffect } from 'react'
import { BsChevronCompactLeft, BsChevronCompactRight, BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { FaCircle } from 'react-icons/fa'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from "gatsby"

const getImageData = graphql`
query {
  allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
    edges {
      node {
        relativePath
        relativeDirectory
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
    }
  }
}
`

const Gallery = () => {
  const data = useStaticQuery(getImageData);

  const [current, setCurrent] = useState(0);
  const length = data.allFile.edges.length;
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const [autoTimer, setAutoTimer] = useState(true);
  const [count, setCount] = useState(0);
  useEffect(() => {
    const tmr = setInterval(() => {
      if (autoTimer) {
        setCount(count + 1);
        setCurrent(current === length - 1 ? 0 : current + 1);
      }
    }, 3000);
    return () => clearInterval(tmr);
  }, [autoTimer, count, current, length]);

  return (
    <div className={styles.gallery} id="gallery">
      <div className={styles.skew1} />
      <div className={styles.skew2} />

      <BsChevronCompactLeft className={`${styles.chevron} ${styles.left}`} onClick={() => {prevSlide(); setAutoTimer(false);}} />
      <BsArrowLeft className={`${styles.arrow} ${styles.left}`} onClick={() => {prevSlide(); setAutoTimer(false);}} />

      {
        data.allFile.edges.map((n, index) => {
          return (
            <GatsbyImage image={getImage(n.node)} alt="Kosmetik & Fußpflege Studio Innenansicht | Nina Jansen | Grevenbroich-Wevelinghoven" title="Kosmetik & Fußpflege Studio Innenansicht | Nina Jansen | Grevenbroich-Wevelinghoven" className={`${styles.image} ${current === index ? styles.imageActive : ''}`} key={index} />
          );
        })
      }

      <section className={styles.indicatorSection}>
        {
          data.allFile.edges.map((n, index) => {
            return (
              <FaCircle key={index} className={`${current === index ? styles.indicatorActive : ''} ${styles.indicator}`} onClick={() => { setCurrent(index) }} />
            );
          })
        }
      </section>

      <BsChevronCompactRight className={`${styles.chevron} ${styles.right}`} onClick={() => {nextSlide(); setAutoTimer(false);}} />
      <BsArrowRight className={`${styles.arrow} ${styles.right}`} onClick={() => {nextSlide(); setAutoTimer(false);}} />

    </div>
  )
}

export default Gallery
