// extracted by mini-css-extract-plugin
export var blur = "ServiceCard-module--blur--qvUAR";
export var closeDetails = "ServiceCard-module--close-details--ANMFc";
export var description = "ServiceCard-module--description--iBKGr";
export var details = "ServiceCard-module--details--WyRGP";
export var detailsDiv = "ServiceCard-module--details-div--K7NES";
export var expandDetails = "ServiceCard-module--expand-details--s-x-Q";
export var expandDetailsDiv = "ServiceCard-module--expand-details-div--J8DNk";
export var expandDetailsIcon = "ServiceCard-module--expand-details-icon--EJhjG";
export var hide = "ServiceCard-module--hide--F8-dO";
export var price = "ServiceCard-module--price--8jBKJ";
export var serviceCard = "ServiceCard-module--service-card--jAn+O";
export var smallHeight = "ServiceCard-module--small-height--43f4Z";
export var title = "ServiceCard-module--title--9KB7z";