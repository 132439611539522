import * as React from "react"
import * as styles from './NavBar.module.css'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { FiMenu, FiX } from 'react-icons/fi'
import { useState } from 'react'


const NavBar = ({ navBarLinks }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleIsMenuOpen = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className={styles.navBar}>
            {isMenuOpen ? (<FiX className={`${styles.navBarMenuIcon} ${styles.navBarX}`} onClick={toggleIsMenuOpen} />) : (<FiMenu className={styles.navBarMenuIcon} onClick={toggleIsMenuOpen} />)}

            <ul className={`${styles.navBarList} ${isMenuOpen ? styles.navBarListActive : ''}`}>
                {navBarLinks.map((item) => {
                    return (
                        <li className={styles.navBarItem} key={item.title}>
                            <AnchorLink
                                to={item.url}
                                title={item.title}
                                className={styles.navBarLink}
                                onAnchorLinkClick={() => {setIsMenuOpen(false);}}
                            />
                        </li>
                    );
                })}
            </ul>
        </nav>
    )
}

export default NavBar