import * as React from "react"
import * as styles from './ServiceCard.module.css'
import { CgCloseO } from 'react-icons/cg'
import { IoIosArrowDropright } from 'react-icons/io'
import { useState } from 'react'



function getPriceString(str) {
    let newStr = str;
    if (newStr.includes(',') && (newStr.length - newStr.indexOf(',') === 2)) {
        newStr = newStr + '0';
    }
    return newStr;
}

const ServiceCard = ({ title, description, price }) => {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <div className={`${styles.serviceCard} ${description === '' ? styles.smallHeight : ''}`}>
            <div className={`${styles.details} ${showDetails ? '' : styles.hide}`}>
                <CgCloseO className={styles.closeDetails} onClick={() => { setShowDetails(false); }} onKeyDown={() => { setShowDetails(false); }} />
                <div className={styles.detailsDiv}><p>{description}</p></div>
            </div>

            <h3 className={styles.title}>{title}</h3>
            <h4 className={styles.price}>{getPriceString(price)} €</h4>
            <div className={`${styles.expandDetails} ${description === '' ? styles.hide : ''}`} onClick={() => { setShowDetails(true) }} onKeyDown={() => { setShowDetails(true) }}>
                <p className={`${styles.description} ${description === '' ? styles.hide : ''}`}>{description}</p>
                <div className={styles.expandDetailsDiv}><IoIosArrowDropright className={`${styles.expandDetailsIcon} ${description.length < 70 ? styles.hide : ''}`} /></div>
            </div>
            <div className={`${styles.blur} ${description === '' ? styles.hide : ''}`}/>
        </div>
    )
}

export default ServiceCard