import * as React from 'react'
import * as styles from './ServicesRow.module.css'
import ServiceCard from './ServiceCard'
import { BsPlusCircle } from 'react-icons/bs'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { useState, useRef } from 'react'
import { useContainerDimensions } from '../../hooks/use-container-dimensions'

function getNumberOfItemsInRow(width) {
    return (Math.floor((width / 340) + (3 / 34)));
}

const ServicesRow = ({ title, services }) => {
    const [isExpanded, setExpanded] = useState(false);

    const componentRef = useRef()
    const {width, height} = useContainerDimensions(componentRef)

    return (
        <section ref={componentRef} className={`${styles.servicesRow} ${isExpanded ? styles.servicesRowIsExpanded : ''} ${getNumberOfItemsInRow(width) > 1 && isExpanded ? styles.servicesRowIsExpandedAddRowGap : ''} ${getNumberOfItemsInRow(width) > 1 ? styles.servicesRowMultipleColumn : styles.servicesRowSingleColumn}`}>
            <h3 className={`${styles.rowTitle} ${getNumberOfItemsInRow(width) > 1 && isExpanded ? styles.rowTitleNoBottomMargin : ''}`}>{title}</h3>
            {
                services.map((item) => {
                    return (
                        <ServiceCard title={item['name']} description={item['description']} price={item['price']} key={item['name'] + item['description']} />
                    )
                })}
            <div className={`${styles.shrinkButton} ${getNumberOfItemsInRow(width) > 1 ? styles.shrinkButtonMultipleColumn : styles.shrinkButtonSingleColumn} ${!isExpanded ? styles.hide : ''}`}>
                <IoCloseCircleOutline className={styles.icon} onClick={() => { componentRef.current.scrollIntoView({ behavior: 'smooth' }); setExpanded(!isExpanded);}} />
            </div>

            <div
                className={`${styles.expandButton} ${getNumberOfItemsInRow(width) > 1 ? styles.expandButtonMultipleColumn : styles.expandButtonSingleColumn} ${(getNumberOfItemsInRow(width) >= services.length) || isExpanded ? styles.hide : ''}`}
                style={
                    {
                        transform: `translateX(-${(width + 30 - 340 * getNumberOfItemsInRow(width)) / 2}px)`,
                    }
                }>
                <BsPlusCircle className={styles.icon} onClick={() => { setExpanded(!isExpanded); }} />
            </div>

        </section>
    )
}

export default ServicesRow