import * as React from "react"
import * as styles from './informationBanner.module.css'
import { ImPhone, ImLocation } from 'react-icons/im'

const InformationBanner = () => {
    return (
        <div className={styles.informationBanner}>
            <span className={styles.location}>
                <ImLocation className={styles.icon} />
                Lerchenweg 39, 41561 Grevenbroich
            </span>
            <span className={styles.phone}>
                <ImPhone className={styles.icon} />
                <a href='tel:+4921817059090' title="Telefon Ninas Beauty Oase">02181 7059090</a>
            </span>
        </div>
    )
}

export default InformationBanner