import * as React from "react"
import './global.css'
import "@fontsource/montserrat"
import "@fontsource/montserrat/200.css"

import InformationBanner from '../components/informationBanner/InformationBanner'
import Header from '../components/header/Header'
import AboutSection from '../components/aboutSection/AboutSection'
import Footer from '../components/footer/Footer'
import Gallery from '../components/gallery/Gallery'
import ServicesContainer from '../components/servicesContainer/ServicesContainer'
import NavBar from '../components/navBar/NavBar'
import Contact from '../components/contactSection/Contact'

import Seo from '../components/seo/Seo'

const navBarLinks = [
  { url: "/#contact", title: "Kontakt" },
  { url: "/#prices", title: "Leistungen" },
  { url: "/#gallery", title: "Impressionen" },
  { url: "/#about", title: "Über mich" },
];

const IndexPage = () => {
  return (
    <main>
      <InformationBanner />
      <NavBar navBarLinks={navBarLinks} />
      <Header />
      <Contact />
      <ServicesContainer />
      <Gallery />
      <AboutSection />
      <Footer />
    </main>
  )
}

export default IndexPage

export const Head = () => (
  <Seo />
)
